<template>
    <div class="m-auto" style="max-width: 960px;">
        <el-card header="基本信息" shadow="never" class="m-3">
            <el-row :gutter="8">
                <el-col :span="24" class="property-group">
                    <span class="property-name">状态</span>
                    <span class="property-value">
                        <router-link :to="'/audit-flow/detail?eventId='+landContract.id" target="_blank">
                            {{landContract.statusName}}
                        </router-link>
                    </span>
                </el-col>
            </el-row>

            <el-row :gutter="8">
                <el-col :span="12" class="property-group">
                    <span class="property-name">编号</span>
                    <span class="property-value">{{landContract.code}}</span>
                </el-col>
                <el-col :span="12" class="property-group">
                    <span class="property-name">名称</span>
                    <span class="property-value">{{landContract.name}}</span>
                </el-col>
            </el-row>

            <el-row :gutter="8">
                <el-col :span="24" class="property-group">
                    <span class="property-name">土地来源方（甲方）</span>
                    <span class="property-value">{{landContract.partnerName}}</span>
                </el-col>
            </el-row>

            <el-row :gutter="8">
                <el-col :span="24" class="property-group">
                    <span class="property-name">土地行政区划</span>
                    <span class="property-value">{{landContract.regionName}}</span>
                </el-col>
            </el-row>

            <el-row :gutter="8">
                <el-col :span="24" class="property-group">
                    <span class="property-name">水发公司（乙方）</span>
                    <span class="property-value">{{landContract.companyName}}</span>
                </el-col>
            </el-row>

            <el-row :gutter="8">
                <el-col :span="12" class="property-group">
                    <span class="property-name">承包开始日期</span>
                    <span class="property-value">{{$moment.sd(landContract.beginContractDate)}}</span>
                </el-col>
                <el-col :span="12" class="property-group">
                    <span class="property-name">承包结束日期</span>
                    <span class="property-value">{{$moment.sd(landContract.endContractDate)}}</span>
                </el-col>
            </el-row>

            <el-row :gutter="8">
                <el-col :span="12" class="property-group">
                    <span class="property-name">土地流转方式</span>
                    <span class="property-value">{{landContract.landTransferMethodName}}</span>
                </el-col>
                <el-col :span="12" class="property-group">
                    <span class="property-name">土地定价方式</span>
                    <span class="property-value">{{landContract.landPriceMethodName}}</span>
                </el-col>
            </el-row>
        </el-card>

        <el-card header="其他签署人" shadow="never" class="m-3">
            <div v-for="(otherSignatory,index) in landContract.otherSignatories" :key="index"
                class="bg-light rounded p-3 my-2">
                {{otherSignatory.receiverName}}
                （{{otherSignatory.receiverMobile}}）
                {{otherSignatory.companyName}}
            </div>
            <el-empty v-if="landContract.otherSignatories.length==0" />
        </el-card>

        <el-card header="面积和价格" shadow="never" class="m-3">
            <el-row :gutter="8">
                <el-col :span="8" class="property-group">
                    <span class="property-name">确权地面积</span>
                    <span class="property-value">{{landContract.propertyArea}} 亩</span>
                </el-col>
                <el-col :span="8" class="property-group">
                    <span class="property-name">确权地单价</span>
                    <span class="property-value">{{landContract.propertyPrice.toFixed(2)}} 元/亩</span>
                </el-col>
                <el-col :span="8" class="property-group">
                    <span class="property-name">确权地金额</span>
                    <span class="property-value">{{landContract.propertyAmount}} 元</span>
                </el-col>
            </el-row>

            <el-row :gutter="8">
                <el-col :span="8" class="property-group">
                    <span class="property-name">口粮地面积</span>
                    <span class="property-value">{{landContract.rationArea}} 亩</span>
                </el-col>
                <el-col :span="8" class="property-group">
                    <span class="property-name">非口粮地面积</span>
                    <span class="property-value">{{landContract.nonRationArea}} 亩</span>
                </el-col>
            </el-row>

            <el-row :gutter="8">
                <el-col :span="8" class="property-group">
                    <span class="property-name">机动地面积</span>
                    <span class="property-value">{{landContract.reservedArea}} 亩</span>
                </el-col>
                <el-col :span="8" class="property-group">
                    <span class="property-name">机动地单价</span>
                    <span class="property-value">{{landContract.reservedPrice.toFixed(2)}} 元/亩</span>
                </el-col>
                <el-col :span="8" class="property-group">
                    <span class="property-name">机动地金额</span>
                    <span class="property-value">{{landContract.reservedAmount}} 亩</span>
                </el-col>
            </el-row>

            <el-row :gutter="8">
                <el-col :span="8" class="property-group">
                    <span class="property-name">合同面积</span>
                    <span class="property-value">{{landContract.contractArea}} 亩</span>
                </el-col>
                <el-col :span="8" class="property-group">
                    <span class="property-name">合同单价</span>
                    <span class="property-value">{{landContract.contractAveragePrice.toFixed(2)}} 元/亩</span>
                </el-col>
                <el-col :span="8" class="property-group">
                    <span class="property-name">合同金额</span>
                    <span class="property-value">{{landContract.contractAmount}} 元</span>
                </el-col>
            </el-row>

            <el-row :gutter="8">
                <el-col :span="8" class="property-group">
                    <span class="property-name">实测面积</span>
                    <span class="property-value">{{landContract.measuredArea}} 亩</span>
                </el-col>
                <el-col :span="8" class="property-group">
                    <span class="property-name">实耕面积</span>
                    <span class="property-value">{{landContract.cultivatedArea}} 亩</span>
                </el-col>
            </el-row>
        </el-card>

        <el-card header="权证附件" shadow="never" class="m-3">
            <multi-file-uploader v-model="landContract.certificationAttachments" readonly />
            <el-empty v-if="landContract.certificationAttachments.length==0" />
        </el-card>

        <el-card header="待签约合同附件" shadow="never" class="m-3">
            <multi-file-uploader v-model="landContract.draftContractAttachments" readonly />
            <el-empty v-if="landContract.draftContractAttachments.length==0" />
        </el-card>

        <el-card header="土地" shadow="never" class="m-3">
            <el-table :data="lands">
                <el-table-column prop="code" label="编号" sortable width="160" />
                <el-table-column prop="name" label="名称" sortable>
                    <template #default="scope">
                        <router-link :to="'/land/detail?id='+scope.row.id" target="_blank">
                            {{scope.row.name}}
                        </router-link>
                    </template>
                </el-table-column>
                <el-table-column prop="typeName" label="类型" sortable width="100" />
                <el-table-column prop="area" label="面积" sortable width="100" />
                <el-table-column prop="isMappingCompletedName" label="测绘状态" sortable width="100" />
            </el-table>
        </el-card>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                id: this.$route.query.id,
                landContract: {
                    otherSignatories: [],

                    propertyArea: 0,
                    propertyAmount: 0,
                    rationArea: 0,

                    reservedArea: 0,
                    reservedAmount: 0,

                    contractArea: 0,
                    contractAmount: 0,
                    measuredArea: 0,
                    cultivatedArea: 0,

                    certificationAttachments: [],
                    draftContractAttachments: [],
                },
                lands: [],
            };
        },
        methods: {
            async loadLandContract() {
                let response = await this.$axios.get('/api/LandContract/GetLandContract', { params: { id: this.id } });
                this.landContract = response.data;
                document.title = this.landContract.code;
            },
            async loadLands() {
                let response = await this.$axios.get('/api/Land/GetLands', { params: { landContractId: this.id } });
                this.lands = response.data;
            },
        },
        created() {
            document.title = this.$store.state.applicationName;

            this.loadLandContract();
            this.loadLands();
        },
    };
</script>

<style>
    .property-group {
        line-height: 40px;
    }

    .property-name {
        display: inline-block;
        color: gray;
    }

    .property-name::after {
        content: '：';
    }

    .property-value {
        display: inline-block;
    }
</style>